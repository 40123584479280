/* Colors: */

$color-theme-hue: 0deg;
$color-theme-saturation: 15%;

$color-theme-0: hsl($color-theme-hue $color-theme-saturation 20.00%);
$color-theme-1: hsl($color-theme-hue $color-theme-saturation 29.38%);
$color-theme-2: hsl($color-theme-hue $color-theme-saturation 38.75%);
$color-theme-3: hsl($color-theme-hue $color-theme-saturation 48.13%);
$color-theme-4: hsl($color-theme-hue $color-theme-saturation 57.50%);
$color-theme-5: hsl($color-theme-hue $color-theme-saturation 66.88%);
$color-theme-6: hsl($color-theme-hue $color-theme-saturation 76.25%);
$color-theme-7: hsl($color-theme-hue $color-theme-saturation 85.63%);
$color-theme-8: hsl($color-theme-hue $color-theme-saturation 95.00%);

$color-signal-action:  royalblue;
$color-signal-success: forestgreen;
$color-signal-warning: #d93a3a;

$color-default-background: white;
$color-default-text:       black;

$color-brand: firebrick;

:root {

	--color-theme-0: $color-theme-0;
	--color-theme-1: $color-theme-1;
	--color-theme-2: $color-theme-2;
	--color-theme-3: $color-theme-3;
	--color-theme-4: $color-theme-4;
	--color-theme-5: $color-theme-5;
	--color-theme-6: $color-theme-6;
	--color-theme-7: $color-theme-7;
	--color-theme-8: $color-theme-8;

	--color-signal-action: $color-signal-action;
	--color-signal-success: $color-signal-success;
	--color-signal-warning: $color-signal-warning;

	--color-default-background: $color-default-background;
	--color-default-text:       $color-default-text;

	--color-brand: $color-brand;

}

/* These need some more specificity: */
:root:root:root {

	.--inert { display: contents; }

	.--clear-top    { margin-top: 0; }
	.--clear-bottom { margin-bottom: 0; }
	.--clear-block  { margin-block: 0; }

	.--align-left   { text-align: left; }
	.--align-center { text-align: center; }
	.--align-right  { text-align: right; }

	.--align-top    { vertical-align: top; }
	.--align-middle { vertical-align: middle; }
	.--align-bottom { vertical-align: bottom; }

	.--text-pretty  { text-wrap: pretty; }
	.--text-balance { text-wrap: balance; }
	.--text-nowrap  { text-wrap: nowrap; }

}


/* Reset: */

*,
::before,
::after {
	box-sizing: border-box;
	margin: 0;
	border-style: none;
	padding: 0;
	font: inherit;
	color: inherit;
}

li {
	list-style-type: none;
}

a {
	text-decoration: none;
}

[hidden] {
	display: none !important; /* Yes, the nuclear option 🤯 */
}

/* Fallback font for Inter: */
@font-face {
	font-family: fallback;
	src: local("Arial");
	size-adjust: 104%;
	line-gap-override: 12%;
}


/* Baseline: */

html {
	interpolate-size: allow-keywords;
	height: 100%;
	overflow-x: clip;
	scroll-behavior: smooth;
	scroll-padding-block: 25px;
	background-color: var(--color-theme-0);
	line-height: 1;
	text-size-adjust: none;
	font-family: "Inter", fallback, sans-serif;
	font-variant-numeric: tabular-nums;
	color: var(--color-default-text);
	-webkit-tap-highlight-color: transparent;
}

body {

	display: flex;
	flex-direction: column;
	min-height: 100%;
	scroll-behavior: smooth;
	scroll-padding-block: 25px;
	background-color: inherit;

	& > .content {
		flex-grow: 1;
	}

}

:focus-visible {
	outline: 2px solid var(--color-signal-action);
	outline-offset: 0;
}


/* Animations: */
@keyframes hide { from { visibility: hidden; } }
@keyframes spin { to { rotate: 1turn; } }
