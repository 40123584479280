.dialog {
	animation: dialog--slide-in 200ms ease-out;
	margin: auto;
	width: calc(100% - 40px);
	max-width: 570px;
	border-radius: 6px;

	&::backdrop {
		opacity: 0.5;
		background-color: black;
	}

}

.dialog__canvas {
	padding: 25px 20px 40px;
}

.dialog__footer {
	display: flex;
	justify-content: flex-end;
	background-color: var(--color-theme-8);
	padding: 20px;

	:is(button, .button) {
		min-width: auto;
	}

}

@keyframes dialog--slide-in {
	from {
		opacity: 0;
		translate: 0 40px;
	}
}
